import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { P } from '../components/typography'

const NotFoundPage = ({ location, className }) => {

  return (
    <Layout
      location={location}
      title={'Page not found'}
      preposition="🕵🏻‍♂️"
      className={className}
    >
      <SEO title="404: Not Found" />
      <section className="max-w-xl mx-auto mb-16">
        <h1 style={{ fontSize: '8rem' }}>
          404
        </h1>
        <P>
          Sorry, that URL doesn't exist!
        </P>
        <Link to="/" className="text-blue-500 underline">
          Go back home &rarr;
        </Link>
      </section>
    </Layout>
  )
}

export default styled(NotFoundPage)``
